<template>
  <div>
    <div class="learn-more">
      <div class="learn-more-content">
        <img src="../../assets/img/nio.png" alt="" class="logo-image" />
        <p class="title">{{ dataSource.title }}</p>
        <p class="desc">{{ dataSource.desc }}</p>
        <a class="learn-more-button" :href="dataSource.deliverUrl" target="_blank">立即投递，加入NIO</a>
        <img src="../../assets/img/nio-new-logo.png" class="logo-nio" />
      </div>
      <img class="recruit-image" :src="homeRecruitImage" alt="" />
    </div>
    <img :src="dataSource.image1" class="image" />
    <img :src="dataSource.image2" class="image" />
  </div>
</template>

<script>
import { campusBannerData, homeRecruitImage } from "@/utils/config";
export default {
  name: "learnNioMore",
  data() {
    return {
      dataSource: campusBannerData,
      homeRecruitImage
    };
  },
};
</script>

<style lang="less" scoped>
.learn-more {
  background: linear-gradient(to bottom, #c5b4a3 10%, #a8c2c2 90%) no-repeat center;
  width: 100%;
  height: 900px;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.learn-more-content {
  flex: none;
  padding-top: 100px;
  padding-left: 80px;
}

.learn-more-button {
  padding: 10px 40px;
  border: 1px solid #000000;
  cursor: pointer;
  font-size: 20px;
  width: 270px;
  color: #000000;
  font-weight: 300;
}

.title {
  font-size: 36px;
  color: #000000;
  line-height: 60px;
  margin-bottom: 8px;
  margin-top: 50px;
  letter-spacing: 4px;
  white-space: pre-wrap;
}

.desc {
  font-size: 24px;
  line-height: 22.2px;
  margin-bottom: 60px;
  margin-top: 20px;
  color: #000000;
  font-weight: 300;
  letter-spacing: 4px;
}

.learn-more-2 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 720px;
}

.learn-more-3 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 720px;
}

.image {
  object-fit: contain;
  object-position: center;
  width: 100%;
}

.logo-nio {
  display: block;
  margin-left: -10px;
  margin-top: 80px;
  width: 150px;
}

.logo-image {
  margin-left: -15px;
  height: 100px;
  width: 90px;
  object-fit: cover;
  object-position: left;
}

.recruit-image {
  flex: 1;
  width: 50%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
</style>
